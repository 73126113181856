<template>
  <section
    class="items-center w-11/12 leading-10 text-center text-gray-500 dark:text-gray-400 lg:mb-4 text-md xl:text-xl xl:leading-extra-loose"
  >
    <slot />
  </section>
</template>

<script>
export default {
  name: 'Section',
};
</script>
